<template>
  <div class="no-scroll-content flex flex-col border border-solid d-theme-border-grey-light d-theme-dark-bg">
    <!------------ box header ------------->
    <div class="flex-none">
      <div class="flex flex-wrap space-x-3 p-2 vx-card shadow-none">
        <div class="flex items-center">
          <label class="mr-1 text-xs whitespace-no-wrap">Proyek</label>
          <vs-select class="vs-select-small" v-model="filter.id_proyek">
            <vs-select-item class="vs-select-small" v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="`${it.kode} - ${it.nama}`"/>
          </vs-select>
        </div>
        <div class="flex items-center">
          <label class="mr-1 text-xs whitespace-no-wrap">Tahun</label>
          <vs-input class="w-full text-xs" v-model="filter.tahun" type="number"/>
        </div>
        <div class="flex items-center">
          <label class="mr-1 text-xs whitespace-no-wrap">Cari</label>
          <vs-input class="w-full text-xs" v-model="filter.search" type="text" @keyup.enter="refresh"/>
        </div>
        <div class="flex items-center">
          <vs-button class="px-7 py-3 text-xs text-center" color="primary" :disabled="loading.filtering" @click="refresh">
            {{ loading.filtering ? 'Loading...' : 'Filter' }}
          </vs-button>
        </div>
      </div>
      <div class="flex justify-between border-t border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1);">
        <div class="flex items-center justify-start space-x-3 px-2 h-8">
          <p class="text-xs font-medium text-dark text-bold">
            <span v-if="loading.filtering">Loading...</span>
            <span v-else>{{ this.allItems.length }} Result</span>
          </p>
        </div>
      </div>
    </div>

    <!----------- box content ------------->
    <div class="flex-1 border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
      <div class="box-content-height overflow-auto">
        <table class="table-fixed table-sticky border-collapse w-full">
          <!-----------TABLE HEAD------------>
          <thead>
            <tr class="d-theme-dark-bg">
              <th class="border border-solid d-theme-border-grey-light px-4 text-xs whitespace-no-wrap text-center w-8"></th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs whitespace-no-wrap text-center w-32">Account Code</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs whitespace-no-wrap text-center w-64">Account Name</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs whitespace-no-wrap text-center w-32">Januari</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs whitespace-no-wrap text-center w-32">Februari</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs whitespace-no-wrap text-center w-32">Maret</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs whitespace-no-wrap text-center w-32">April</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs whitespace-no-wrap text-center w-32">Mei</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs whitespace-no-wrap text-center w-32">Juni</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs whitespace-no-wrap text-center w-32">Juli</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs whitespace-no-wrap text-center w-32">Agustus</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs whitespace-no-wrap text-center w-32">September</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs whitespace-no-wrap text-center w-32">Oktober</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs whitespace-no-wrap text-center w-32">November</th>
              <th class="border border-solid d-theme-border-grey-light px-2 text-xs whitespace-no-wrap text-center w-32">Desember</th>
            </tr>
          </thead>

          <!-----------TABLE BODY------------>
          <tbody v-for="group in data.groups" :key="group.uuid">
            <tr>
              <td colspan="15" class="border border-solid d-theme-border-grey-light font-medium px-2 py-1 text-xs whitespace-no-wrap">
                <div class="flex items-center cursor-pointer" @click="group.expanded = !group.expanded">
                  <feather-icon :icon="group.expanded ? 'MinusSquareIcon' : 'PlusSquareIcon'" svg-classes="h-5 w-5" class="mr-2"/>
                  <span>{{ group.name }}</span>
                </div>
              </td>
            </tr>
            <template class="ml-1" v-if="group.expanded">
              <tr v-for="item in group.items" :key="item.uuid" :class="{'bg-warning-transparent-10': !item.id}" @click="data.selected = item">
                <td class="border border-solid d-theme-border-grey-light text-xs whitespace-no-wrap text-center">
                  <vs-icon v-if="item === data.selected" size="14px" color="danger" icon-pack="material-icons" icon="play_arrow"/>
                </td>
                <td class="border border-solid d-theme-border-grey-light font-medium px-2 py-1 text-xs text-left">{{ item.kode_coa }}</td>
                <td class="border border-solid d-theme-border-grey-light font-medium px-2 py-1 text-xs text-left">{{ item.nama_coa }}</td>
                <td class="border border-solid d-theme-border-grey-light font-medium p-0 text-xs whitespace-no-wrap"><v-money theme="theme3" v-model="item.jan"/></td>
                <td class="border border-solid d-theme-border-grey-light font-medium p-0 text-xs whitespace-no-wrap"><v-money theme="theme3" v-model="item.feb"/></td>
                <td class="border border-solid d-theme-border-grey-light font-medium p-0 text-xs whitespace-no-wrap"><v-money theme="theme3" v-model="item.mar"/></td>
                <td class="border border-solid d-theme-border-grey-light font-medium p-0 text-xs whitespace-no-wrap"><v-money theme="theme3" v-model="item.apr"/></td>
                <td class="border border-solid d-theme-border-grey-light font-medium p-0 text-xs whitespace-no-wrap"><v-money theme="theme3" v-model="item.mei"/></td>
                <td class="border border-solid d-theme-border-grey-light font-medium p-0 text-xs whitespace-no-wrap"><v-money theme="theme3" v-model="item.jun"/></td>
                <td class="border border-solid d-theme-border-grey-light font-medium p-0 text-xs whitespace-no-wrap"><v-money theme="theme3" v-model="item.jul"/></td>
                <td class="border border-solid d-theme-border-grey-light font-medium p-0 text-xs whitespace-no-wrap"><v-money theme="theme3" v-model="item.aug"/></td>
                <td class="border border-solid d-theme-border-grey-light font-medium p-0 text-xs whitespace-no-wrap"><v-money theme="theme3" v-model="item.sep"/></td>
                <td class="border border-solid d-theme-border-grey-light font-medium p-0 text-xs whitespace-no-wrap"><v-money theme="theme3" v-model="item.okt"/></td>
                <td class="border border-solid d-theme-border-grey-light font-medium p-0 text-xs whitespace-no-wrap"><v-money theme="theme3" v-model="item.nov"/></td>
                <td class="border border-solid d-theme-border-grey-light font-medium p-0 text-xs whitespace-no-wrap"><v-money theme="theme3" v-model="item.des"/></td>
              </tr>
            </template>
          </tbody>

          <!-----------TABLE FOOT------------>
          <tfoot v-if="data.groups.length > 0">
            <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs whitespace-no-wrap text-right"></th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs whitespace-no-wrap text-right"></th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs whitespace-no-wrap text-right"></th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs whitespace-no-wrap text-right">{{ summary.grandTotalJanuari | idr }}</th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs whitespace-no-wrap text-right">{{ summary.grandTotalFebruari | idr }}</th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs whitespace-no-wrap text-right">{{ summary.grandTotalMaret | idr }}</th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs whitespace-no-wrap text-right">{{ summary.grandTotalApril | idr }}</th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs whitespace-no-wrap text-right">{{ summary.grandTotalMei | idr }}</th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs whitespace-no-wrap text-right">{{ summary.grandTotalJuni | idr }}</th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs whitespace-no-wrap text-right">{{ summary.grandTotalJuli | idr }}</th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs whitespace-no-wrap text-right">{{ summary.grandTotalAgustus | idr }}</th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs whitespace-no-wrap text-right">{{ summary.grandTotalSeptember | idr }}</th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs whitespace-no-wrap text-right">{{ summary.grandTotalOktober | idr }}</th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs whitespace-no-wrap text-right">{{ summary.grandTotalNovember | idr }}</th>
              <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs whitespace-no-wrap text-right">{{ summary.grandTotalDesember | idr }}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <!------------ box footer ------------->
    <div class="flex-none">
      <div class="flex flex-wrap items-center p-2 justify-between">
        <div class="flex items-center space-x-2">
          <vs-button v-if="$can('create_account_budgeting')" color="success" size="small" class="px-3" icon-pack="feather" icon="icon-plus" @click="modalAdd.active = true">Add</vs-button>
          <vs-button v-if="$can('update_account_budgeting')" color="danger" size="small" class="px-3" icon-pack="feather" icon="icon-trash" :disabled="!data.selected" @click="onDeleteClicked">Delete</vs-button>
          <vs-button v-if="$can('delete_account_budgeting')" color="primary" size="small" class="px-3" icon-pack="feather" icon="icon-save" :disabled="!isAnyUnsaved || loading.saving" @click="save">{{ loading.saving ? 'Saving...' : 'Save' }}</vs-button>
        </div>
      </div>
    </div>

    <!--modals-->
    <vs-popup class="sm:popup-w-70 popup-content-no-padding"
              title="Pilih Data COA"
              :active="modalAdd.active"
              v-on:update:active="modalAdd.active = $event">
      <Coa :selectable="true" :externalFilter="filterCoa" multi-select :disable-ids="itemsIdCoa" @selected="onSelectedCoa"/>
    </vs-popup>
  </div>
</template>

<script>
import ProyekRepository from '@/repositories/master/proyek-repository'
import AccountBudgetingRepository from '@/repositories/accounting/account-budgeting-repository'
import VMoney from '@/views/components/v-money/VMoney'
import moment from 'moment'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'

export default {
  name: 'AccountBudgeting',
  components: {
    VMoney,
    Coa: () => import('@/views/pages/master/coa/Coa')
  },
  mounted () {
    this.getProyek()
  },
  computed: {
    allItems () {
      const groups = this.data.groups
      return _.flatMap(groups, group => group.items)
    },
    itemsIdCoa () {
      return _.map(this.allItems, item => item.id_coa)
    },
    isAnyUnsaved () {
      return !_.isEqual(this.allItems, this.originalItems)
    },
    summary () {
      return {
        grandTotalJanuari: _.sumBy(this.allItems, item => parseFloat(item.jan)),
        grandTotalFebruari: _.sumBy(this.allItems, item => parseFloat(item.feb)),
        grandTotalMaret: _.sumBy(this.allItems, item => parseFloat(item.mar)),
        grandTotalApril: _.sumBy(this.allItems, item => parseFloat(item.apr)),
        grandTotalMei: _.sumBy(this.allItems, item => parseFloat(item.mei)),
        grandTotalJuni: _.sumBy(this.allItems, item => parseFloat(item.jun)),
        grandTotalJuli: _.sumBy(this.allItems, item => parseFloat(item.jul)),
        grandTotalAgustus: _.sumBy(this.allItems, item => parseFloat(item.aug)),
        grandTotalSeptember: _.sumBy(this.allItems, item => parseFloat(item.sep)),
        grandTotalOktober: _.sumBy(this.allItems, item => parseFloat(item.okt)),
        grandTotalNovember: _.sumBy(this.allItems, item => parseFloat(item.nov)),
        grandTotalDesember: _.sumBy(this.allItems, item => parseFloat(item.des))
      }
    },
    filterCoa () {
      return {
        tipe: 'DETAIL',
        id_proyek: this.filter.id_proyek
      }
    }
  },
  data () {
    return {
      loading: {
        filtering: false,
        saving: false
      },
      proyeks: [],
      modalAdd: {
        active: false
      },
      filter: {
        id_proyek: null,
        tahun: moment().format('YYYY'),
        search: null
      },
      data: {
        selected: null,
        groups: []
      },
      originalItems: []
    }
  },
  methods: {
    onSelectedCoa (items) {
      const rows = _.map(items, item => {
        return {
          uuid: uuid(),
          id_proyek: this.filter.id_proyek,
          tahun: this.filter.tahun,
          group_coa: item.group,
          id_coa: item.id,
          kode_coa: item.kode,
          nama_coa: item.nama,
          jan: 0,
          feb: 0,
          mar: 0,
          apr: 0,
          mei: 0,
          jun: 0,
          jul: 0,
          aug: 0,
          sep: 0,
          okt: 0,
          nov: 0,
          des: 0
        }
      })

      // push items
      _.each(rows, row => {
        const group = _.find(this.data.groups, group => group.name === row.group_coa)
        if (group) {
          group.items.push(row)
        } else {
          this.data.groups.push({
            uuid: uuid(),
            expanded: true,
            name: row.group_coa,
            items: [row]
          })
        }
      })

      this.save()
      this.modalAdd.active = false
    },

    getProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getData () {
      this.loading.filtering = true

      const params = {
        id_proyek: this.filter.id_proyek,
        tahun: this.filter.tahun,
        search: this.filter.search || ''
      }
      AccountBudgetingRepository.get(params)
        .then(response => {
          this.data.groups = this.buildGroups(response.data.data)
          this.originalItems = _.cloneDeep(this.allItems)
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading.filtering = false
        })
    },

    buildGroups (items) {
      const buildItems = (groupName) => {
        const filteredItems = _.filter(items, item => item.group_coa === groupName)
        return _.map(filteredItems, item => {
          item.uuid = uuid()
          return item
        })
      }

      return _.map(_.uniqBy(items, item => item.group_coa), it => {
        return {
          uuid: uuid(),
          expanded: true,
          name: it.group_coa,
          items: buildItems(it.group_coa)
        }
      })
    },

    save () {
      this.loading.saving = true

      const params = { items: this.allItems }
      AccountBudgetingRepository.save(params)
        .then(response => {
          this.clearSelected()
          this.notifySuccess('Tersimpan')
          this.refresh()
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading.saving = false
        })
    },

    delete (item) {
      AccountBudgetingRepository.delete(item.id)
        .then(response => {
          this.removeRow(item.uuid)
          this.clearSelected()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    clearSelected () {
      this.data.selected = null
    },

    refresh () {
      this.data.groups = []
      this.originalItems = []
      this.getData()
    },

    async onDeleteClicked () {
      const item = this.data.selected
      if (item.id) {
        this.confirmDelete(item)
      } else {
        this.removeRow(item.uuid)
        this.clearSelected()
      }
    },

    removeRow (uuid) {
      const groupIndex = _.findIndex(this.data.groups, { items: [{ uuid: uuid }] })
      const group = this.data.groups[groupIndex]
      const itemIndex = _.findIndex(group.items, { uuid: uuid })
      group.items.splice(itemIndex, 1)
      if (group.items.length < 1) {
        this.data.groups.splice(groupIndex, 1)
      }
    },

    confirmDelete (item) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: `Yakin ingin hapus ${this.data.selected.nama_coa}?`,
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(item) }
      })
    }
  }
}
</script>

<style scoped>
/*navbar floating*/
.navbar-floating .box-content-height {
  height: calc(100vh - 283px);
}
.navbar-floating.footer-sticky .box-content-height {
  height: calc(100vh - 306px);
}
.navbar-floating.footer-hidden .box-content-height {
  height: calc(100vh - 252px);
}

/*navbar sticky*/
.navbar-sticky .box-content-height {
  height: calc(100vh - 269px);
}
.navbar-sticky.footer-sticky .box-content-height {
  height: calc(100vh - 292px);
}
.navbar-sticky.footer-hidden .box-content-height {
  height: calc(100vh - 238px);
}

/*navbar static*/
.navbar-static .box-content-height {
  height: calc(100vh - 251px);
}
.navbar-static.footer-sticky .box-content-height {
  height: calc(100vh - 274px);
}
.navbar-static.footer-hidden .box-content-height {
  height: calc(100vh - 220px);
}

/*navbar hidden*/
.navbar-hidden .box-content-height {
  height: calc(100vh - 206px);
}
.navbar-hidden.footer-sticky .box-content-height {
  height: calc(100vh - 229px);
}
.navbar-hidden.footer-hidden .box-content-height {
  height: calc(100vh - 175px);
}

/*table sticky*/
.table-sticky thead {
  position: sticky;
  top: -1px;
  z-index: 101;
}
.table-sticky tfoot {
  position: sticky;
  bottom: -1px;
  z-index: 101;
}
</style>
